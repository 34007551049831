import {Button, Card, Collapse, Form, Input, Select} from "antd";
import {Edit, useDataProvider, useEditContext, useNotify, useRedirect} from "react-admin";
import React, {useState} from "react";
import {ExtendedDataProvider} from "@src/types";
import TextArea from "antd/es/input/TextArea";
import DeleteButton from "@src/components/Common/DeleteButton"
import OpenAIModelForm from "@src/components/LLMModels/LLMForms/OpenAIModelForm";
import PrivateLLMModelForm from "@src/components/LLMModels/LLMForms/PrivateLLMModelForm";

const { Option } = Select;


const ALLMModelsCreateForm = (props: any) => {
    const [form] = Form.useForm ();
    const notify = useNotify ();
    const redirect = useRedirect ();
    const dataProvider: ExtendedDataProvider = useDataProvider ();
    const {record, loading, loaded} = useEditContext ();
    const [selectedLLMModelType, setSelectedLLMModelType] = useState(record?record?.extra_data?.model_type:"OpenAI");

    const [oldApiKey, setOldApiKey] = useState(record?record?.extra_data?.OPENAI_API_KEY:null);


    const resource = "llm_models";
    const title = "LLM Model";

    const onSubmit = (values: any) => {
        const extra_data = {}
        Object.entries(values).map(([key, value]) => {
            if(!(key==="name" || key==="prompt")){
                delete values[key]
                // @ts-ignore
                extra_data[key] = value
            }
        })
        values["extra_data"] = extra_data

        if (values?.extra_data?.OPENAI_API_KEY === "******")
            values["extra_data"]["OPENAI_API_KEY"] = oldApiKey

        if (record) {
            dataProvider.update(resource, { id: record.id, data: values, previousData: { id: record.id } })
                .then(() => {
                    notify(`${title} updated successfully`);
                    redirect(`/${resource}`)
                })
                .catch((error: any) => {
                    notify("Something went wrong", "error")
                    if (error.status == 400)
                        for (const [key, value] of Object.entries<any>(error.body))
                            form.setFields([{ name: key, errors: value }])
                })

        } else {
            dataProvider.create(resource, {data: values})
                .then(() => {
                    notify(`${title} created successfully`);
                    redirect(`/${resource}`)
                })
                .catch((error: any) => {
                    notify("Something went wrong", "error")
                    if (error.status == 400)
                        for (const [key, value] of Object.entries<any>(error.body))
                            form.setFields([{name: key, errors: value}])
                })
        }
    }

    return (
        <Form
            form={form}
            name="create_llm_model"
            labelCol={{ span: 6 }}
            className="row-col"
            style={{marginLeft: "10px"}}
            labelAlign="left"
            initialValues={record?
                {
                    ...record,
                    OPENAI_API_KEY: record?"******":null,
                    OPENAI_ASSISTANT_ID: record?.extra_data?.OPENAI_ASSISTANT_ID,
                    model_type: record?.extra_data?.model_type
                }:
                { remember: true, trust_env: true, verify: true }}
            onFinish={onSubmit}
        >

            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter a Name' }]}>
                <Input placeholder="Name"/>
            </Form.Item>


            <Form.Item label={"Prompt"} name={"prompt"} rules={[{ required: true }]}>
                <TextArea rows={20} placeholder="Prompt" maxLength={10000} />
            </Form.Item>

            <Form.Item label={"Choose LLM Model Type"} name={"model_type"}>
                <Select
                    style={{ width: 200 }}
                    placeholder="Select your LLM Model Type"
                    onChange={(value) => setSelectedLLMModelType(value)}
                    // defaultValue={record?record?.extra_data?.model_type:selectedLLMModelType}
                    value={selectedLLMModelType}
                >
                    <Option value="OpenAI">OpenAI</Option>
                    <Option value="PrivateLLM">Private LLM</Option>
                </Select>
            </Form.Item>

            {selectedLLMModelType==="OpenAI" && <OpenAIModelForm record={record} />}
            {selectedLLMModelType==="PrivateLLM" && <PrivateLLMModelForm record={record} />}

            <Form.Item>
                {
                    <Button style={{ width: "20%" }} type="primary" htmlType="submit" className="form-button">{record? "Update":"Create"}</Button>
                }
                {
                    record &&
                    <DeleteButton title={"LLM Model"} resource={"llm_models"} style={{ width: "20%", float: "right" }} record={record} />
                }
            </Form.Item>
        </Form>

    )
}

const ALLMModelsCreate = (props: any) => {

    return (
        <>
            <Card
                className=" header-solid h-full ant-card pt-0"
                bordered={false}
                title={"LLM Model"}
            >
                {props.id &&
                    <Edit {...props}>
                        <ALLMModelsCreateForm />
                    </Edit>
                }
                {!props.id && <ALLMModelsCreateForm />}
            </Card>
        </>
    )
}

export default ALLMModelsCreate;
